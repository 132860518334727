import React from 'react';
import { AppRoute } from '..';

export const appRoutes: Array<AppRoute> = [
  // pages
  {
    title: 'Menu',
  },
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: React.lazy(() => import('@mui/icons-material/WidgetsOutlined')),
    component: React.lazy(() => import('../../pages/dashboard/Dashboard')),
    default: true,
  },
  {
    title: 'Staging Jobs',
  },
  {
    title: 'New Staging Job',
    path: '/migrations/create',
    icon: React.lazy(() => import('@mui/icons-material/Add')),
    component: React.lazy(
      () => import('../../pages/staging/create-staging-job')
    ),
  },
  {
    path: '/migrations/edit/:id',
    icon: React.lazy(() => import('@mui/icons-material/Edit')),
    component: React.lazy(() => import('../../pages/staging/edit-staging-job')),
    hideInMenu: true,
  },
  {
    title: 'Staging Jobs',
    path: '/migrations',
    icon: React.lazy(() => import('@mui/icons-material/CloudUploadOutlined')),
    component: React.lazy(() => import('../../pages/migrations/Migrations')),
  },
  {
    title: 'Customers',
  },
  {
    title: 'Customers',
    path: '/rialto-broadcloud/customers',
    icon: React.lazy(() => import('@mui/icons-material/PeopleAltOutlined')),
    component: React.lazy(() => import('../../pages/customers/Customers')),
  },

  {
    path: '*',
    component: React.lazy(() => import('../../pages/error/Error')),
    hideInMenu: true,
  },
];
