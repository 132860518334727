import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRoute, appRoutes, Container, useSession } from '.';

const LoginComponent = React.lazy(() => import('../pages/login/Login'));
const LoginVerifyComponent = React.lazy(
  () => import('../pages/login/LoginVerify')
);

function Router() {
  const { user } = useSession();
  const defaultRoute = appRoutes.find((route) => route.default) ?? appRoutes[0];
  const allRoutes = defaultRoute
    ? [{ ...defaultRoute, path: '/' }, ...appRoutes]
    : appRoutes;
  return (
    <Routes>
      {/* App Routes (Authenticated) */}
      {user &&
        allRoutes
          .filter((r: AppRoute) => r.path?.length)
          .map((r: AppRoute, i) => {
            const { component: Comp, path } = r;
            if (Comp)
              return (
                <Route
                  key={i}
                  path={path}
                  element={
                    <Suspense fallback={<Container loading={true} />}>
                      <Container loading={false} children={<Comp />} />
                    </Suspense>
                  }
                />
              );
            return <></>;
          })}

      {/* Unauthenticated routes (login, 404 etc) */}
      <Route path="login" element={<LoginComponent />} />
      <Route path="login/verify" element={<LoginVerifyComponent />} />
    </Routes>
  );
}

export default Router;
