import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@emotion/react';
import { appTheme, Http } from './lib';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const fetchLicenseKey = async () => {
  try {
    const licenseKey = await Http.get<string>('jwks/mui', 'text');

    if (typeof licenseKey === 'string' && licenseKey) {
      LicenseInfo.setLicenseKey(licenseKey);
    } else {
      console.warn(
        'MUI license key not found or invalid. Make sure the endpoint returns the license key correctly.'
      );
    }
  } catch (error: any) {
    console.error('Error fetching MUI license key:', error.message);
    console.error(
      'Raw response:',
      error.response ? error.response.data : 'No response data'
    );
  }
};

const initApp = async () => {
  await fetchLicenseKey();

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={appTheme}>
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

initApp();
