import { getCookie } from 'typescript-cookie';
import authService from '../auth/auth.service';

class Http {
  public static timeout = 30000 * 10; // 30 seconds

  private static getAuthHeaders = (): Headers => {
    const token = getCookie('token');
    let headers: any = {
      'Content-Type': 'application/json',
    };

    if (token?.length) headers.Authorization = `Bearer ${token}`;

    return new Headers(headers);
  };

  private static handleResponse = async <T>(
    res: Response,
    responseType: 'json' | 'text'
  ): Promise<T> => {
    if (res.status === 401) {
      authService.logout(true);
    }

    if (responseType === 'text') {
      return res.text() as unknown as T;
    } else {
      return res.json() as T;
    }
  };

  public static get = async <T>(
    path: string,
    responseType: 'json' | 'text' = 'json'
  ): Promise<T> => {
    const controller = new AbortController();
    const requestTimer = setTimeout(() => controller.abort(), this.timeout);
    controller.signal.addEventListener('abort', controller.abort);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        method: 'get',
        signal: controller.signal,
        headers: this.getAuthHeaders(),
      });
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);

      return await this.handleResponse<T>(res, responseType);
    } catch (error) {
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);
      throw error;
    }
  };

  // Similarly update the post, put, patch, and delete methods to accept responseType parameter
  public static post = async <T>(
    path: string,
    body?: any,
    responseType: 'json' | 'text' = 'json'
  ): Promise<T> => {
    const controller = new AbortController();
    const requestTimer = setTimeout(() => controller.abort(), this.timeout);
    controller.signal.addEventListener('abort', controller.abort);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        method: 'post',
        signal: controller.signal,
        body: body ? JSON.stringify(body) : undefined,
        headers: this.getAuthHeaders(),
      });
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);

      return await this.handleResponse<T>(res, responseType);
    } catch (error) {
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);
      throw error;
    }
  };

  public static put = async <T>(
    path: string,
    body?: any,
    responseType: 'json' | 'text' = 'json'
  ): Promise<T> => {
    const controller = new AbortController();
    const requestTimer = setTimeout(() => controller.abort(), this.timeout);
    controller.signal.addEventListener('abort', controller.abort);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        method: 'PUT',
        signal: controller.signal,
        body: body ? JSON.stringify(body) : undefined,
        headers: this.getAuthHeaders(),
      });
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);

      return await this.handleResponse<T>(res, responseType);
    } catch (error) {
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);
      throw error;
    }
  };

  public static patch = async <T>(
    path: string,
    body?: any,
    responseType: 'json' | 'text' = 'json'
  ): Promise<T> => {
    const controller = new AbortController();
    const requestTimer = setTimeout(() => controller.abort(), this.timeout);
    controller.signal.addEventListener('abort', controller.abort);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        method: 'PATCH',
        signal: controller.signal,
        body: body ? JSON.stringify(body) : undefined,
        headers: this.getAuthHeaders(),
      });
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);

      return await this.handleResponse<T>(res, responseType);
    } catch (error) {
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);
      throw error;
    }
  };

  public static delete = async <T>(
    path: string,
    body?: any,
    responseType: 'json' | 'text' = 'json'
  ): Promise<T> => {
    const controller = new AbortController();
    const requestTimer = setTimeout(() => controller.abort(), this.timeout);
    controller.signal.addEventListener('abort', controller.abort);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        method: 'delete',
        signal: controller.signal,
        body: body ? JSON.stringify(body) : undefined,
        headers: this.getAuthHeaders(),
      });
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);

      return await this.handleResponse<T>(res, responseType);
    } catch (error) {
      clearTimeout(requestTimer);
      controller.signal.removeEventListener('abort', controller.abort);
      throw error;
    }
  };
}

export default Http;
